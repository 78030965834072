import {
  BaseStepIdEnum,
  StepConfig,
  StepTypeEnum,
  FieldTypeEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  // {
  //   id: BaseStepIdEnum.company_search,
  // },
  // {
  //   id: BaseStepIdEnum.company_list,
  // },
  {
    id: 'company_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'street_address',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'street_address_2',
          nested: 'address',
          hasPlaceholder: true,
          isRequired: false,
          isEnabled: false,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'city',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'state',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country',
          nested: 'address',
          type: FieldTypeEnum.country,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country_of_fiscal_residence',
          type: FieldTypeEnum.country,
          isRequired: false,
          isEnabled: true,
          hasHelper: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  // {
  //   key: BaseStepEnum.affiliated_companies_list,
  // },
  {
    id: 'source_of_funds',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'source_of_funds',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          isEnabled: true,
          options: [
            'retained_earnings',
            'activity_incomes',
            'sale_of_assets',
            'capital_injection',
            'dividends',
            'investment_funds',
            'venture_capital_funding',
            'private_investors',
            'grants',
            'bank_loan',
            'crowdfunding',
            'donations',
            'ipo',
          ],
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: false, hasSignatory: false },
  },
];
