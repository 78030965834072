const brandColors = {
  primary: '#44169C',
  secondary: '#44169C',
};

const sidebarColors = {
  backgroundColor: '#EBE4FC',
  backgroundImage: 'url(./assets/sidebarBackground.png)',
  color: '#FFFFFF',
  dotfileLogo: '#FFFFFF',
};

const linkColors = {
  color: '#238DFF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
